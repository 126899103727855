.mdc-text-field {
  margin: 0 20px 10px 0;
}

.stepper.custom-theme {
  font-family: 'Roboto', sans-serif;
}

.stepper__head__index {
  background: #2878c2!important;
}

:root {
  --mdc-theme-primary: #2878c2;
  --mdc-theme-secondary: #2878c2;
}

.mdc-button {
  text-transform: none;
  font-size: 1rem;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  word-spacing: normal;
}

label::after {
  font-size: 20px!important;
  height: 10px;
}

.stepper--horizontal {
  /*width: 80%;*/
}
