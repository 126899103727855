.content-container {
  margin-top: 70px;
  min-height: calc(100vh - 261px);
}

a.active.item {
  background-color: #cacbcd !important;
  color: black !important;
}

.image-gallery-icon {
  color: #1a69a4;
}

.hero-image {
  height: 50% !important;
  width: 50% !important;
}

.mid-page-info {
  font-size: 1.7em;
  text-align: left;
  text-justify: inter-character;
  padding-left: 2em;
  padding-right: 2em;
}

body {
  margin-top: -70px !important;
  padding-top: 70px !important;
}

.payearned-blue-dark {
  color: #005482;
}

.payearned-blue {
  color: #0085c8;
}

.requiredStar:after {
  content:" *";
  color: red;
}

a:hover {
  cursor:pointer;
  text-decoration: underline;
}

[tabulator-field="scheduledValue"], [tabulator-field="retainage"] {
  text-align: right;
}

.info-btn {
  position: absolute;
  top: 4.7em;
  right: 18%;
}

.guide-modal {
  width: 40% !important;
}

.free-account-warning > h3.header:not(.medium) {
  color: #4183c4 !important;
}

.free-account-warning > h3.header.medium {
  color: red !important;
}

.free-account-warning > h3.header.normal {
  color: black !important;
}

.free-account-warning > h3.header {
  margin-bottom: 0.75em !important;
}

.new-project {
  flex: 1;
  /* display: flex !important;
  align-items: center; */
}

.new-project.subscribed {
  display: flex !important;
  align-items: center;
}

.new-project.item {
  display: flex !important;
  align-items: center;
}

.new-project.item.first h2 {
  color: red !important;
}

.new-project.item.second h2 {
  margin-top: 0.75rem !important;
}

.new-project.item.fourth {
  margin-top: 0.75rem !important;
}

.new-project h2 {
  margin-right: 0.5em !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.watch-video {
  margin-top: 0.5em !important;
}

/*Mobile view styles*/
@media only screen and (max-width: 700px) {
  .mid-page-info {
    padding-left: 0;
    padding-right: 0;
  }
  .hero-image {
    height: 100% !important;
    width: 100% !important;
  }
  .info-btn {
    top: 11%;
    right: 18%;
  }
  .guide-modal {
    width: 90% !important;
  }
}
