button.tabulator-page {
  background: #2878c2!important;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease;
  -webkit-tap-highlight-color: transparent;
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: rgba(0,0,0,.87);
  cursor: not-allowed;
  background-color: rgba(0,0,0,.05) !important;
  pointer-events: none;
}

button.tabulator-page[data-page="prev"]:before {
  font-family: "Font Awesome 5 Free";
  content: "\f053";
  display: contents;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

button.tabulator-page[data-page="next"]:after {
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  display: contents;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

button.tabulator-page[data-page="first"]:before {
  font-family: "Font Awesome 5 Free";
  content: "\f100";
  display: contents;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}


button.tabulator-page[data-page="last"]:after {
  font-family: "Font Awesome 5 Free";
  content: "\f101";
  display: contents;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
