input[type="date"] {
  width: 180px!important;
}

input {
  text-overflow: ellipsis;
}

input:hover {
  overflow: visible!important;
  text-overflow: unset!important;
  white-space: normal!important;
}

label::after {
  font-size: 30px!important;
  height: 20px;
}

/*
 * Format the edit table to make the first column narrower
 */
div.fields > table.ui.celled.selectable > thead > tr > th:first-child {
  width: auto!important;
}

div.fields > table.ui.celled.selectable > tbody > tr > td:first-child > div {
  width: 35px!important;
}
/*
 * End of first column width formatting
 */

/*Project Lines table header*/
h2.edit-application-header ~ form > div:nth-child(4) table > thead > tr:nth-child(1) > th {
  font-size: 1.2em;
  position: sticky;
  top: 48px;
  z-index: 10;
}

/*Project Lines table second header*/
h2.edit-application-header ~ form > div:nth-child(4) > div > table > thead > tr:nth-child(2) > th {
  width: 100%;
  position: sticky;
  top: 98px;
  z-index: 10;
}

/*Change Orders table header*/
h2.edit-application-header ~ form > div:nth-child(5) table > thead > tr:nth-child(1) > th {
  font-size: 1.2em;
  position: sticky;
  top: 48px;
  z-index: 10;
  color: red;
}

/*Change Orders table second header*/
h2.edit-application-header ~ form > div:nth-child(5) > div > table > thead > tr:nth-child(2) > th {
  width: 100%;
  position: sticky;
  top: 98px;
  z-index: 10;
}

/*Change Orders table second header # field*/
h2.edit-application-header ~ form > div:nth-child(5) > div > table > thead > tr:nth-child(2) > th:nth-child(1) {
  color: red;
}

/*Change Orders table # field rows */
h2.edit-application-header ~ form > div:nth-child(5) > div > table > tbody > tr > td:nth-child(1) > div > div > input {
  color: red;
}
