.ui.form .disabled.field, .ui.form .field.disabled>label, .ui.disabled.input {
  opacity: 1!important;
}
input:disabled {
  opacity: 1!important;
  border: none!important;
  padding-left: 0!important;
  padding-right: 0!important;
}
.ui.fluid.input>input:disabled {
  width: 30px!important;
}
